<template>
  <Layout>
    <div
      style="
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
      "
    >
      <div class="base-class">
        <div class="top-item" @click="goSearch('通用接龙')">
          <el-image
            class="top-image"
            :src="require('@/assets/images/home/tyjl-back.png')"
          ></el-image>
          <p class="top-title">通用接龙</p>
          <div style="display: flex; margin-left: 31px">
            <p class="top-count">表单</p>
            <p class="top-count" style="margin-left: 10px">问卷</p>
            <p class="top-count" style="margin-left: 10px">登记</p>
            <p class="top-count" style="margin-left: 10px">填表</p>
          </div>
          <div>
            <p class="creat-text">点击立即免费创建>></p>
          </div>
        </div>
        <div class="top-item" @click="goSearch('签到打卡')">
          <el-image
            class="top-image"
            :src="require('@/assets/images/home/qddk-back.png')"
          ></el-image>
          <p class="top-title">签到打卡</p>
          <div style="display: flex; margin-left: 31px">
            <p class="top-count">周期性多次收集信息</p>
          </div>
          <div>
            <p class="creat-text">点击立即免费创建>></p>
          </div>
        </div>
        <div class="top-item">
          <el-image
            class="top-image"
            :src="require('@/assets/images/home/jjqd-back.png')"
          ></el-image>
          <p class="top-title">敬请期待</p>
          <div style="display: flex; margin-left: 31px">
            <p class="top-count"></p>
          </div>
          <div>
            <p
              class="creat-text"
              style="
                margin-top: 21px;
                text-align: left;
                margin-left: 30px;
                font-weight: 300;
              "
            >
              新功能开发中.......
            </p>
          </div>
        </div>
      </div>

      <div class="base-class">
        <div
          class="click-item"
          v-for="topItem in clickItems"
          :key="topItem.id"
          @click="goSearch(topItem.titleStr)"
        >
          <el-image
            class="click-image"
            :src="require('@/assets/images/home/' + topItem.imageStr + '.png')"
          ></el-image>
          <div style="text-align: right; width: 138px">
            <p class="click-title">{{ topItem.titleStr }}</p>
            <p class="click-creat">点击立即免费创建>></p>
          </div>
        </div>
      </div>

      <div class="base-class" style="display: block">
        <div v-for="(item, index) in tableData" :key="index">
          <div class="model-top">
            <p class="model-top-title">{{ item.name }}</p>
            <p
              class="model-top-more"
              style="cursor: pointer"
              @click="lookMareClick(item.name)"
            >
              查看更多>>
            </p>
          </div>

          <div style="display: flex; flex-wrap: wrap">
            <div
              class="model-item"
              v-for="(sitem, index) in item.array"
              :key="index"
               @click="creatClick(sitem)"
            >
              <el-image
                class="click-image"
                :src="imageUrl + sitem.cover_img"
                style="
                  background-color: bisque;
                  margin: 11px;
                  border-radius: 3px;
                "
              ></el-image>

              <div
                style="width: 200px; "
               
              >
                <p class="model-item-title">{{ sitem.name }}</p>
                <p class="model-item-count">{{ sitem.auxiliarytitle }}</p>
              </div>
              <div class="free-button">免费使用</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { getHomePageTempleList } from "@/utils/api/activity";
import { imageUrl } from "@/utils/config/commonUrl.js";
export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      imageUrl: imageUrl,
      clickItems: [
        {
          imageStr: "hdbm",
          titleStr: "活动报名",
          id: 21,
        },
        {
          imageStr: "zyjl",
          titleStr: "作业接龙",
          id: 22,
        },
        {
          imageStr: "dkjl",
          titleStr: "打卡接龙",
          id: 23,
        },
        {
          imageStr: "qdjl",
          titleStr: "签到接龙",
          id: 24,
        },
        {
          imageStr: "xxsj",
          titleStr: "信息收集",
          id: 25,
        },
      ],

      tableData: [
        {
          name: "通用接龙",
          array: [],
        },
        {
          name: "签到打卡",
          array: [],
        },
      ],
    };
  },
  created() {
    //如果没登录跳转登录页
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") == "小程序码已失效，请刷新"
    ) {
      // this.$message("小程序码已失效，请刷新");
      this.$router.replace("/login");
    }

    this.getMessage();
  },

  methods: {
    async getMessage() {
      // 通用接龙
      await getHomePageTempleList({
        templateIndex: 3,
        pageNum: 1,
        pageSize: 9,
      }).then((res) => {
        console.log(res, "请求列表数据1");
        if (res.status == 1) {
          this.tableData[0].array = res.data.list;
        }
      });

      // 签到打卡
      await getHomePageTempleList({
        templateIndex: 4,
        pageNum: 1,
        pageSize: 9,
      }).then((res) => {
        console.log(res, "请求列表数据2");
        if (res.status == 1) {
          this.tableData[1].array = res.data.list;
        }
      });
    },

    // 跳转到搜索列表页
    goSearch(typeStr) {
      console.log(typeStr);
      switch (typeStr) {
        case "通用接龙":
          // this.$router.push({
          //   path: "/stencilSearch",
          //   query: { templateIndex: 3,isType: 106},
          // });
          // eslint-disable-next-line no-case-declarations
          let routeData = this.$router.resolve({
            path: "/stencilSearch",
            query: { templateIndex: 3, isType: 106 },
          });

          window.open(routeData.href, "_blank");
          break;
        case "签到打卡":
          // eslint-disable-next-line no-case-declarations
          let routeData2 = this.$router.resolve({
            path: "/stencilSearch",
            query: { templateIndex: 4, isType: 109 },
          });
          window.open(routeData2.href, "_blank");
          break;
        case "活动报名":
          // eslint-disable-next-line no-case-declarations
          let routeData3 = this.$router.resolve({
            path: "/stencilSearch",
            query: { templateIndex: 1, classifyName: "活动报名", isType: 106 },
          });
          window.open(routeData3.href, "_blank");
          break;
        case "作业接龙":
          // this.$router.push({
          //   path: "/stencilSearch",
          //   query: { templateIndex: 1, classifyName: "作业收集", isType: 106 },
          // });

          // eslint-disable-next-line no-case-declarations
          let routeData4 = this.$router.resolve({
            path: "/stencilSearch",
            query: { templateIndex: 1, classifyName: "作业收集", isType: 106 },
          });
          window.open(routeData4.href, "_blank");
          break;
        case "打卡接龙":
          // this.$router.push({
          //   path: "/solitaire",
          //   query: { activityId: 2, selectType: "add", isType: 109 },
          // });
          // eslint-disable-next-line no-case-declarations
          // let routeData5 = this.$router.resolve({
          //   path: "/solitaire",
          //   query: { activityId: 2, selectType: "add", isType: 109 },
          // });
          // window.open(routeData5.href, "_blank");
          // eslint-disable-next-line no-case-declarations
          let routeData5 = this.$router.resolve({
            path: "/stencilSearch",
            query: { templateIndex: 1, classifyName: "打卡", isType: 109 },
          });
          window.open(routeData5.href, "_blank");
          break;
        case "签到接龙":
          // this.$router.push({
          //   path: "/stencilSearch",
          //   query: { templateIndex: 1, classifyName: "签到", isType: 109 },
          // });
          // eslint-disable-next-line no-case-declarations
          let routeData6 = this.$router.resolve({
            path: "/stencilSearch",
            query: { templateIndex: 1, classifyName: "签到", isType: 109 },
          });
          window.open(routeData6.href, "_blank");
          break;
        case "信息收集":
          // this.$router.push({
          //   path: "/stencilSearch",
          //   query: { templateIndex: 2, isType: 106 },
          // });
          // eslint-disable-next-line no-case-declarations
          let routeData7 = this.$router.resolve({
            path: "/stencilSearch",
            query: { templateIndex: 2, isType: 106 },
          });
          window.open(routeData7.href, "_blank");
          break;

        default:
          break;
      }
    },

    // 查看更多
    lookMareClick() {
      // this.$router.push({
      //   path: "/modelList",
      // });

      let routeData = this.$router.resolve({
        path: "/modelList",
      });
      window.open(routeData.href, "_blank");
    },

    // 进入创建页面
    creatClick(item) {
      // console.log(item, "item");
      // this.$router.push({
      //   path: "/solitaire",
      //   query: {
      //     activityId: item.id,
      //     selectType: "add",
      //     isType: item.activity_type,
      //   },
      // });

      let routeData = this.$router.resolve({
        path: "/solitaire",
        query: {
          activityId: item.id,
          selectType: "add",
          isType: item.activity_type,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.base-class {
  display: flex;
  width: 1236px;
}
.top-item {
  cursor: pointer;
  width: 397px;
  height: 120px;
  margin-right: 15px;
  cursor: pointer;
}
.top-image {
  width: 397px;
  height: 120px;
  position: absolute;
  z-index: -1;
}
.top-title {
  color: #ffffff;
  line-height: 33px;
  font-size: 24px;
  font-weight: 400;
  margin-left: 31px;
  margin-top: 18px;
  margin-bottom: 5px;
}

.top-count {
  color: #ffffff;
  line-height: 22px;
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 13px;
}
.creat-text {
  color: #ffffff;
  line-height: 22px;
  text-align: right;
  font-size: 14px;
  margin-right: 10px;
}

.click-item {
  cursor: pointer;
  width: 235px;
  height: 93px;
  margin-right: 12px;
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
}
.click-image {
  width: 66px;
  height: 66px;
  margin-top: 13px;
  margin-left: 22px;
}

.click-title {
  font-size: 19px;
  color: #2b2f36;
  line-height: 28px;
  margin-top: 21px;
  margin-bottom: 12px;
  margin-right: 5px;
}

.click-creat {
  color: #a2a2a2;
  line-height: 20px;
  font-size: 13px;
}

.model-top {
  justify-content: space-between;
  display: flex;
  width: 1220px;
  margin-top: 20px;
}
.model-top-title {
  color: #2b2f36;
  line-height: 21px;
  font-size: 18px;
  font-weight: 550;
}
.model-top-more {
  color: #1877ff;
  line-height: 19px;
  font-size: 14px;
  // text-decoration: underline;
  border-bottom: 1px solid #1877ff;
}
.model-item {
  width: 400px;
  height: 88px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.model-item-title {
  color: #000000;
  line-height: 22px;
  font-size: 17px;
  margin-top: 18px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}
.model-item-count {
  color: #676767;
  line-height: 20px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.free-button {
  cursor: pointer;
  width: 80px;
  height: 31px;
  background-color: white;
  border-radius: 18px;
  border: solid 1px #1877ff;
  text-align: center;
  line-height: 31px;
  color: #1877ff;
  font-size: 13px;
  margin-top: 30px;
  margin-left: 10px;
}
</style>